@if $nav-type == flyout {
  @if $warn == true {
    @warn ("Don't forget to set JS variable flyoutNav to true!");
  }
  .pageHeader {
    position: relative;

    .headerContent {
      max-width: 100% !important; // This only really works with a full width header
    }
  }

  .menuMainAlt {
    display: block;
  }

  .menuMain {
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 0;
    bottom: 0;
    height: 100%;
    max-height: 100% !important;
    width: 400px;
    right: -400px;
    overflow-y: auto;
    transition: all $transition-duration $transition-curve;
    z-index: 2;
    background-color: $nav-background-colour;
    padding: 20px 0;

    &.active {
      right: 0;
      max-height: 100% !important;
    }

    // We're cloning in the button to the menu so we can use it to close
    .menuMainAlt {
      position: relative;
      right: auto;
      bottom: auto;
      transform: none;
      display: inline-flex;
      align-self: flex-start;
      margin: $nav-top-level-item-padding;

      &:before {
        content: "\f00d";
      }
    }

    .topLevel > li > a {
      color: $nav-top-level-item-colour;

      &:hover {
        color: $nav-top-level-item-hover-colour;
      }
    }

    // Potential fix for odd height bug in IOS Safari
    // See https://www.bugherd.com/projects/174228/tasks/23
    .mainCallToAction {
      display: block;
    }
  }

  .subMenu {
    width: 100%;
    &.level2,
    &:not(.level2) {
      border-radius: 0;
    }
  }

  .subMenu.active {
    display: block;
  }

  //
  // Responsive
  //

  @media (max-width: 500px) {
    .menuMain {
      width: 90%;
      right: -90%;
    }
  }
}
