// Transparent header over banner images
@if $header-absolute == true {
  @media (min-width: $header-absolute-breakpoint + 1px) {
    body:not(.subsite).page-has-banner {
      .pageHeader {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        background: $header-absolute-background;
        z-index: 3;
      }
    }
  }
}